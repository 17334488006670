
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  Form,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "../../components/Navbars/DemoNavbar.js";
import CardsFooter from "../../components/Footers/CardsFooter.js";
import { Link } from "react-router-dom";

// index page sections
import Download from "../IndexSections/Download.js";
import SimpleFooter from "../../components/Footers/SimpleFooter.js";

class Terms extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  targetElementRef = React.createRef();

  navigateToElement = () => {
    if (this.targetElementRef.current) {
      this.targetElementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  targetElementRef2 = React.createRef();

  navigateToElement2 = () => {
    if (this.targetElementRef.current) {
      this.targetElementRef2.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  targetElementRef3 = React.createRef();

  navigateToElement3 = () => {
    if (this.targetElementRef.current) {
      this.targetElementRef3.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  BoldText({children}) {
    return <span style={{fontWeight: 'bold'}}>{children}</span>;
  }

  render() {
    return (
      <>
        <DemoNavbar navigateToElement={this.navigateToElement} />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped">
              <div className="shape shape-style-1 shape-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="6">
                      <h1 className="display-3 text-darkGrey">
                        FarFera{" "}
                      </h1>
                      <h2 className="text-darkGrey">
                        Terms and Conditions
                      </h2>
                      <p className="text-darkGrey">
                        Effective: February 28, 2025
                      </p>                    
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-darkGrey"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg  mt--100" >
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-1 pt-100">
                  <div className="pr-md-5">
                    <h5 className='text-creamy'>
                    PLEASE READ THESE CONSUMER TERMS AND CONDITIONS CAREFULLY. THESE
                    CONSUMER TERMS AND CONDITIONS (“AGREEMENT,” “TERMS AND
                    CONDITIONS,” or “TERMS”) CONSTITUTE A LEGAL AGREEMENT BETWEEN YOU AND
                    FARFERA, AS DEFINED BELOW.
                    </h5>
                    <p className='text-default'>
                    The validation of your online transaction form is considered as an irrevocable
                    acceptance of these terms.
                    </p>
                    <h5 className='text-creamy'>
                    1. Application of this Agreement
                    </h5>
                    <p className='text-default'>
                    This Agreement governs your use of the Technology and Services (each as defined below) 
                    and is between you and FarFera, sarl. a Moroccan corporation, and its subsidiaries and 
                    affiliated companies, including, without limitation, the entities listed in the remainder of this 
                    paragraph. With respect to Moroccan Orders, these Terms and Conditions constitute a legal 
                    agreement between you and FarFera, sarl. and/or its subsidiaries and affiliated companies.
                    <br></br>
                    <br></br>
                    The following online sales terms govern all transactions established through FarFera 
                    web site or applications. Any transaction processed through this web site assumes 
                    the unconditional and irrevocable acceptance of these conditions by the customer.
                    <br></br>
                    <br></br>
                    These terms are intended to define the rights and obligations of the parties in the 
                    context of the online sales done via FarFera web site or applications.
                    <br></br>
                    <br></br>
                    Certain provisions of this Agreement apply based on the country of your primary residence or 
                    the country in which you have selected a delivery or pickup address, and such provisions are 
                    designated as such using one or more of the following definitions:
                    <br></br>
                    <br></br>
                    &nbsp;  • Morocco Consumer: means your primary residence is in Morocco.<br></br>
                    &nbsp;  • Morocco Order: means you have selected a delivery or pickup address in Morocco.
                    </p>
                    <h5 className='text-creamy'>
                    2. Acceptance of this Agreement
                    </h5>
                    <p className='text-default'>
                    FarFera uses its online marketplace platform to connect you and other consumers, 
                    restaurants and other businesses (“<this.BoldText>Merchants”</this.BoldText>), and independent third-party contractors 
                    who provide delivery and/or other services (“<this.BoldText>Contractors</this.BoldText>”). FarFera’s Technology (as 
                    defined below) permits consumers to place orders for food and/or other goods from 
                    Merchants, either for delivery or pickup, and/or request services from Merchants. If a delivery 
                    order is made, FarFera uses the Technology to notify Contractors (or, for certain orders, 
                    Merchants) that a delivery opportunity is available and to facilitate completion of the delivery 
                    to the consumer. If a pickup order is made, FarFera uses the Technology to communicate 
                    with the consumer regarding the availability of the order for pickup. FarFera is not a 
                    merchant, retailer, restaurant, grocer, delivery service, or food preparation business (except 
                    as otherwise specified in Section 6 below).
                    If you access any our website located at <a href="https://www.FarFera.com" target="_blank">https://www.FarFera.com/</a>, install or use the FarFera
                    mobile application, install or use any other technology supplied by FarFera (collectively, the 
                    “<this.BoldText>Technology</this.BoldText>”), or access or use any information, function, feature, or service made 
                    available or enabled by FarFera (collectively, the “<this.BoldText>Services</this.BoldText>,” which includes the 
                    Technology), click or tap a button or take similar action to signify your affirmative acceptance 
                    of this Agreement, or complete the FarFera account registration process, you, your heirs, 
                    assigns, and successors (collectively, “<this.BoldText>you</this.BoldText>” or “<this.BoldText>your</this.BoldText>”) hereby represent and warrant that:
                    <br></br>
                    <br></br>
                    &nbsp;  a) you have read, understand, and agree to be bound by this Agreement and any future 
                        amendments and additions to this Agreement as published from time to time 
                        at https://www.FarFera.com/terms/ or through the Technology.
                        <br></br>
                    &nbsp;  b) you are of legal age in the jurisdiction in which you reside to form a binding contract 
                        with FarFera.
                        <br></br>
                    &nbsp;  c) you have the authority to enter into the Agreement personally and, if applicable, on 
                        behalf of any organization on whose behalf you have created an account or named 
                        as the User during the FarFera account registration process and to bind such 
                        organization to the Agreement.
                    </p>
                    <p  className='text-default'>
                    The specific Services available to you may vary based on the delivery or pickup address that 
                    you have selected. A certain function, feature, or Service (y) available to one User may not 
                    be available to all Users or at all times, and (z) may only be available in the latest version of 
                    the FarFera mobile applications. The terms “<this.BoldText>User</this.BoldText>” and “<this.BoldText>Users</this.BoldText>” refer to all individuals and 
                    other persons who access or use the Services, including, without limitation, any 
                    organizations that register accounts or otherwise access or use the Services through their 
                    respective employees, agents, or representatives. Except as otherwise provided in this 
                    Agreement, if you do not agree to be bound by the Agreement, you may not access or use 
                    the Services.
                    </p>
                    <h5 className='text-creamy'>
                    3. Modifications
                    </h5>
                    <p  className='text-default'>
                    FarFera reserves the right to modify the terms and conditions of this Agreement or its 
                    policies relating to the Technology or Services at any time, effective upon posting an updated 
                    version of this Agreement at <a href="https://www.FarFera.com/terms/" target="_blank">https://www.FarFera.com/terms/</a> or through the Technology. If 
                    we make any material changes to this Agreement, we will notify you by email at the email 
                    address that you have provided to us or by another means. You should regularly review this 
                    Agreement, as your continued use of the Services after any such changes constitutes your 
                    agreement to such changes. If you do not agree to these modifications, you should 
                    immediately cease using the Technology and Services.
                    </p>
                    <h5 className='text-creamy'>
                    4. Additional Terms and Policies
                    </h5>
                    <p  className='text-default'>
                    By using the Services, you agree to be bound by this Agreement and acknowledge and 
                    agree to the collection, use, and disclosure of your personal information in accordance with 
                    FarFera’s Privacy Policy, which is incorporated in this Agreement by reference. You also 
                    agree to abide by any additional FarFera terms or policies for Users that are published on 
                    our website or mobile application, whether or not such terms or policies are directly 
                    referenced or linked elsewhere in this Agreement. Certain features of our Services may be 
                    subject to additional terms and conditions, which, to the extent permitted by applicable law, 
                    are incorporated herein by reference.
                    <br></br>
                    <br></br>
                    Our application integrates with third-party services, including Uber's API, to enhance user 
                    experience by providing [specific features, e.g., ride-sharing, delivery services]. 
                    Through this integration, we may collect and share certain personal data with Uber Technologies, 
                    Inc. ('Uber'). This data includes, but is not limited to, location information, contact details,
                    and usage patterns. The purpose of this data sharing is to facilitate and improve the services 
                    offered through our application. We ensure that any data shared with Uber is transmitted securely
                    and used in accordance with Uber's Privacy Policy.
                    </p>
                    <h5 className='text-creamy'>
                    5. Rules and Prohibitions
                    </h5>
                    <p  className='text-default'>
                    Without limiting other rules and prohibitions in this Agreement, by using the Services, you 
                    agree that:
                    <br></br>
                    (a) You will only use the Services for lawful purposes and you will not use or attempt to use 
                    the Services for sending or storing any unlawful material or for deceptive or fraudulent 
                    purposes.
                    <br></br>
                    (b) You will only use the Services in accordance with all applicable laws, including 
                    copyrights, trade secrets, other intellectual property rights, or other rights of any third party, 
                    including privacy, personality, or publicity rights.
                    <br></br>
                    (c) You will only use or access the Services using means explicitly authorized by FarFera. If 
                    applicable, it is your responsibility to ensure you download the correct Technology for your 
                    device. We are not liable if you do not have a compatible device or if you have downloaded 
                    the wrong version of the Technology for your device. We reserve the right to terminate your 
                    use of the Technology and/or Services should you be using the Technology or Services with 
                    an incompatible or unauthorized device.
                    <br></br>
                    (d) You will not use or attempt to use another User’s account, impersonate any person or 
                    entity, or forge or manipulate headers or identifiers to disguise the origin of any content 
                    transmitted through the Services.
                    <br></br>
                    (e) You will not use or attempt to use the Services to cause nuisance, annoyance, or 
                    inconvenience.
                    <br></br>
                    (f) You will not use or attempt to use the Services, or any content accessible through the 
                    Services, for any commercial purpose, including but not limited to contacting, advertising to, 
                    soliciting, or selling to any Merchant, User, or Contractor, unless FarFera has given you prior 
                    permission to do so in writing.
                    <br></br>
                    (g) You will not copy or distribute, or attempt to copy or distribute, the Technology or any 
                    content displayed through the Services, including any reviews or Merchants’ menu content or 
                    catalogs, for republication in any format or media.
                    <br></br>
                    (h) You will not directly or indirectly create or compile, or attempt to create or compile, any 
                    content or collection, compilation, or other directory from any content displayed through the 
                    Services except for your personal, noncommercial use.
                    <br></br>
                    (i) The information you provide to us when you register an account or otherwise 
                    communicate with us is accurate, you will promptly notify us of any changes to such 
                    information, and you will provide us with whatever proof of identity we may reasonably 
                    request.
                    <br></br>
                    (j) You will keep secure and confidential your account password and any other login or 
                    identification credentials you use to access the Services.
                    <br></br>
                    (k) You will use the Technology and Services only for your own use and will not directly or 
                    indirectly resell, license, or transfer the Technology, Services, or content displayed by the 
                    Services to a third party.
                    <br></br>
                    (l) You will not use or attempt to use the Services in any way that could damage, disable, 
                    overburden, or impair any FarFera server or the networks connected to any FarFera server.
                    <br></br>
                    (m) You will not attempt to gain unauthorized access to any part of the Technology or the 
                    Services and/or to any account, resource, computer system, and/or network connected to 
                    any FarFera server.
                    <br></br>
                    (n) You will not probe, scan, or test the vulnerability of any system or network or breach or 
                    circumvent any security or authentication measures FarFera may use to prevent or restrict 
                    access to the Services or use of the Services or the content therein, and you will not attempt 
                    any of the foregoing.
                    <br></br>
                    (o) You will not deep-link to our websites or access our websites manually or with any robot, 
                    spider, web crawler, extraction software, automated process, and/or device to scrape, copy, 
                    index, frame, or monitor any portion of our websites or any content on our websites, and you 
                    will not attempt any of the foregoing.
                    <br></br>
                    (p) You will not scrape or otherwise conduct any systematic retrieval of data or other content 
                    from the Services, and you will not attempt any of the foregoing.
                    <br></br>
                    (q) You will not engage in conduct that harms, attempts to harm, or threatens the safety of 
                    other Users, Merchants, Contractors, FarFera, FarFera employees, or our community in any 
                    way whatsoever, and you will take reasonable steps to prevent the foregoing.
                    <br></br>
                    (r) You will not engage in threatening, harassing, racist, or sexist behavior or any other 
                    behavior that FarFera deems inappropriate when using the Services.
                    <br></br>
                    (s) You will report any errors, bugs, unauthorized access methodologies, or any breach of 
                    our intellectual property rights that you uncover in your use of the Services.
                    <br></br>
                    (t) You will not abuse or attempt to abuse our promotional or credit code system, including by 
                    redeeming multiple coupons at once or by opening multiple accounts to benefit from offers 
                    available only to first-time Users.
                    <br></br>
                    (u) Your participation in using the Services is for your sole, personal, or internal business 
                    use.
                    <br></br>
                    (v) You will not falsely or fraudulently claim that your order or items from your order were 
                    missing, incorrect, of poor quality, defective, or never delivered.
                    <br></br>
                    In the event that we believe or determine that you have breached any of the aforementioned, 
                    we reserve the right to suspend and/or permanently deactivate your account at our sole 
                    discretion.
                    </p>
                    <h5 className='text-creamy'>
                    6. Contractors and Merchants Are Independent
                    </h5>
                    <p  className='text-default'>
                    (a) You understand and agree that FarFera provides the Services to connect you with 
                    independent Merchants that provide the products and services offered through the Services, 
                    and independent third-party Contractors who provide delivery and other services. You 
                    acknowledge and agree that FarFera is not a merchant, retailer, restaurant, grocer, 
                    pharmacy, chemist, delivery service, or food preparation business, and has no responsibility 
                    or liability for the acts or omissions of any Merchant or any Contractor. Merchants are the 
                    retailers of the products or services offered through the Services. FarFera is not in the 
                    delivery business, does not provide delivery services, and is not a common carrier. FarFera
                    provides the Services to facilitate the transmission of orders by Users to Merchants, including 
                    orders for pickup or delivery by Contractors and/or Merchants. FarFera will not assess or 
                    guarantee the suitability, legality, or ability of any Contractor or Merchant. You agree that 
                    FarFera is not responsible for the Merchants’ food preparation or product offerings, food or 
                    product handling, or the safety of the food or other products, or whether the photographs, 
                    images, menu or product listings, catalog, item descriptions, or other menu, product, or 
                    catalog information (including nutrition, ingredient, and/or allergen information) displayed 
                    through the Services accurately reflect the goods and services sold by the Merchants and/or 
                    delivered by the Contractor and/or Merchant, and does not verify Merchants’ compliance with 
                    applicable laws or regulations. You also acknowledge and agree that menu, product, or 
                    catalog listings, descriptions, or other information (including photographs or images or 
                    nutrition, ingredient, and/or allergen information) displayed through the Services may not 
                    have been provided directly by the Merchant. FarFera has no responsibility or liability for acts 
                    or omissions by any Merchant or Contractor. You agree that the goods that you purchase will 
                    be prepared by the Merchant you have selected, that title to the goods passes from the 
                    Merchant to you at the Merchant’s location, and that, for delivery orders, the Contractor 
                    and/or Merchant will be directed by your instructions to transport the products to your 
                    designated delivery location. You agree that neither the Contractor nor FarFera holds title to 
                    or acquires any ownership interest in any goods that you order through the Services. You 
                    must not do anything which seeks to create an encumbrance, lien, charge, or other interest 
                    in or over the goods that you order until title has passed to you.
                    </p>
                    <h5 className='text-creamy'>
                    7. User Account
                    </h5>
                    <p  className='text-default'>
                    You may be required to register for an account to use parts of the Services. You must 
                    provide accurate, current, and complete information during the registration process and at all 
                    other times when you use the Services, and to update the information to keep it accurate, 
                    current, and complete. You are the sole authorized User of any account you create through 
                    the Services. You are solely and fully responsible for all activities that occur under your 
                    password or account. You agree that you shall monitor your account to prevent use by 
                    minors, and you will accept full responsibility for any unauthorized use of your password or 
                    your account. You may not authorize others to use your User account, and you may not 
                    assign or otherwise transfer your User account to any other person or entity. Should you 
                    suspect that any unauthorized party may be using your password or account, you will notify 
                    FarFera immediately. FarFera will not be liable, and you may be liable, for losses, damages, 
                    liability, expenses, and fees incurred by FarFera or a third party arising from someone else 
                    using your account, regardless of whether you have notified us of such unauthorized use. If 
                    you provide any information that is untrue, inaccurate, not current, or incomplete, or if 
                    FarFera has reasonable grounds to suspect that such information is untrue, inaccurate, not 
                    current, or incomplete, FarFera has the right to suspend or terminate your account and 
                    refuse any and all current or future use of the Services (or any portion thereof). We may 
                    enable or require you to use a single pair of login credentials to use the FarFera services. 
                    You agree not to create an account or use the Services if you have been previously removed 
                    by FarFera, or if you have been previously banned from use of the Services.
                    </p>
                    <h5 className='text-creamy'>
                    8. User Content
                    </h5>
                    <p  className='text-default'>
                    (a) <this.BoldText>User Content</this.BoldText>. FarFera may provide you with interactive opportunities through the 
                    Services, including, by way of example, the ability to post or otherwise provide to FarFera
                    Ratings and Reviews (as defined below), Feedback (as defined below), or other text, photos, 
                    images, or audio and video content (collectively, “User Content”). You represent and 
                    warrant that you are the owner of, or otherwise have the right to provide, all User Content 
                    that you submit, post, and/or otherwise transmit through the Services. You further represent 
                    and warrant that any User Content submitted, posted, and/or otherwise transmitted through 
                    the Services by you or someone on your behalf or through your User account does not 
                    <br></br>
                    &nbsp;  I. violate any third-party right, including any copyright, trademark, patent, trade secret, 
                    privacy right, right of publicity, or any other intellectual property or proprietary right.
                    <br></br>
                    &nbsp;  II. contain material that is false, intentionally misleading, deceptive, defamatory, 
                    offensive, abusive, or pornographic, including material that racially or religiously 
                    vilifies, incites violence or hatred, or is likely to insult or humiliate others based on 
                    race, religion, ethnicity, gender, age, sexual orientation, or any physical or mental 
                    disability.
                    <br></br>
                    &nbsp;  III. contain sexually explicit or violent content or photos, images, or videos of weapons, 
                    illegal drugs, or hate symbols.
                    <br></br>
                    &nbsp;  IV. contain any material that is unlawful or relates to unlawful conduct (including phishing 
                    and spoofing).
                    <br></br>
                    &nbsp;  V. create a privacy or security risk to any person, including by soliciting personal 
                    information from any person, or contain any confidential, sensitive, private, or 
                    personally identifiable information.
                    <br></br>
                    &nbsp;  VI. solicit money from any person.
                    <br></br>
                    &nbsp;  VII. contain financial, legal, medical, or other professional advice.
                    <br></br>
                    &nbsp;  VIII. harm, abuse, harass, stalk, threaten, or otherwise offend.
                    <br></br>
                    &nbsp;  IX. reflect negatively on FarFera, including FarFera’s goodwill, name, and reputation.
                    <br></br>
                    &nbsp;  X. tamper with, hinder the operation of, or make unauthorized modifications to our 
                    websites or Technology.
                    <br></br>
                    &nbsp;  XI. otherwise result in civil or criminal liability for you, FarFera, or any third party.
                    <br></br> 
                    &nbsp;  XII. violate any law or regulation.
                    <br></br>
                    &nbsp;  XIII. violate this Agreement or any community or content guidelines that FarFera may
                    publish from time to time (including but not limited to the FarFera Review Guidelines). 
                    <br></br>
                    You hereby grant FarFera (including FarFera’s service providers) a perpetual, irrevocable, 
                    transferable, fully paid, royalty-free, non-exclusive, worldwide, fully sublicensable right and 
                    license to use, copy, display, publish, modify, remove, publicly perform, translate, create 
                    derivative works from, distribute, and/or otherwise use the User Content in connection with 
                    FarFera’s business and in all forms now known or hereafter invented (collectively, “Uses”), 
                    without notification to and/or approval by you. You further grant FarFera a license to use your 
                    username, first name and last initial, profile photo (if available), and/or other User profile 
                    information, including, without limitation, your ratings history, to attribute User Content to you 
                    in connection with such Uses, without notification to or approval by you. You agree that this 
                    license includes the right for other Users to access and use your User Content in conjunction 
                    with participation in the Services and as permitted through the functionality of the Services. In 
                    the interest of clarity, the license granted to FarFera herein shall survive termination of the 
                    Services or your account. FarFera reserves the right in its sole discretion to remove or 
                    disable access to any User Content from the Services, suspend or terminate your account at 
                    any time, or pursue any other remedy or relief available under equity or law if you post any 
                    User Content that violates this Agreement or any community or content guidelines we may 
                    publish or that we consider to be objectionable for any reason. You agree that FarFera may 
                    monitor and/or delete your User Content (but does not assume the obligation to do so) or 
                    may decide to not publish, display, or otherwise make available your User Content for any 
                    reason at FarFera’s sole discretion. FarFera may also access, read, preserve, and disclose 
                    any information as FarFera reasonably believes is necessary to satisfy any applicable law, 
                    regulation, legal process, or governmental request. enforce this Agreement, including 
                    investigation of potential violations hereof. detect, prevent, or otherwise address fraud, 
                    security, or technical issues. respond to User, Contractor, or Merchant support requests. or 
                    protect the rights, property, or safety of FarFera, our Users, and the public.
                    <br></br>
                    (b) <this.BoldText>Feedback</this.BoldText>. You agree that any submission of any ideas, suggestions, and/or proposals to 
                    FarFera through its suggestion, feedback, wiki, forum, or similar pages (“Feedback,” which is 
                    considered User Content) is at your own risk and that FarFera has no obligations (including, 
                    without limitation, obligations of confidentiality) with respect to such Feedback. You represent 
                    and warrant that you have all rights necessary to submit the Feedback and you hereby grant 
                    to FarFera (including FarFera’s service providers) a perpetual, irrevocable, transferable, fully 
                    paid, royalty-free, non-exclusive, worldwide, fully sublicensable right and license to use, 
                    copy, display, publish, modify, remove, publicly perform, translate, create derivative works 
                    from, distribute, and/or otherwise use such Feedback.
                    <br></br>
                    (c) <this.BoldText>Ratings and Reviews</this.BoldText>. To the extent that you rate or post reviews of Merchants or other 
                    businesses, which may include but is not limited to text, photos, images, audio, or videos that 
                    you provide (“Ratings” and “Reviews”), such Ratings and Reviews are considered User 
                    Content and are governed by this Agreement. Ratings and Reviews are not endorsed by 
                    FarFera and do not represent the views of FarFera or its affiliates. FarFera shall have no 
                    liability for Ratings and Reviews or for any claims of economic loss resulting from such 
                    Ratings and Reviews. Because we strive to maintain a high level of integrity with respect to 
                    Ratings and Reviews posted or otherwise made available through the Services, you 
                    acknowledge and agree that: 
                    <br></br>
                    &nbsp;  I. you will base any Rating or Review on first-hand experience with the Merchant or 
                    business.
                    <br></br>
                    &nbsp;  II. you will not provide a Rating or Review for any Merchant or business for which you 
                    have an ownership interest, employment relationship, or other affiliation or for any 
                    competitor of such a Merchant or business.
                    <br></br>
                    &nbsp;  III. you will not submit a Rating or Review in exchange for payment, free food items, or 
                    other benefits from a Merchant or business.
                    <br></br>
                    &nbsp;  IV. your Rating or Review will comply with the terms of this Agreement.
                    <br></br>
                    &nbsp;  V. we may decide to not publish, display, or otherwise make available your Rating or 
                    Review for any reason in our sole discretion.
                    <br></br>
                    &nbsp;  VI. any Rating or Review you submit will comply with our Review Guidelines. 
                    <br></br>
                    If we determine, in our sole discretion, that any Rating or Review could diminish the integrity 
                    of the Ratings and Reviews or otherwise violates this Agreement, we may remove such 
                    Rating or Review without notice and take any additional action, in FarFera’s sole discretion, 
                    as we deem necessary or appropriate.
                    </p>
                    <h5 className='text-creamy'>
                    9. Communications with FarFera
                    </h5>
                    <p  className='text-default'>
                    By creating a FarFera account or using the Technology or Services, you agree to accept and 
                    receive communications from FarFera, Contractors, Merchants, or third parties providing 
                    services to you or FarFera, including via email, text message, direct message, chat, calls, 
                    and push notifications to the cellular telephone number you provided to FarFera. You 
                    understand and agree that you may receive communications generated by automatic 
                    telephone dialing systems and/or which will deliver prerecorded messages sent by or on 
                    behalf of FarFera and/or Contractors, including but not limited to communications concerning 
                    orders placed through your account on the Services. Message and data rates may apply. For 
                    certain types of communications (for example, marketing, research, or product updates), you 
                    may be able to adjust the emails, text messages, push notifications, or other communications 
                    you receive from us in the FarFera mobile application, on our website, or through 
                    mechanisms or functionality available in the communication. For purposes of clarity, any text 
                    message you may receive from us, a Merchant, or a Contractor regarding an order is a 
                    transactional text message, not a promotional text message.
                    </p>
                    <h5 className='text-creamy'>
                    10. Electronic Records
                    </h5>
                    <p  className='text-default'>
                    By creating a FarFera account, you also consent to the use of an electronic record to 
                    document your agreement. You may withdraw your consent to the use of the electronic
                    record by emailing FarFera at <a href="mailto:FarFera@FarFera.com?body=My custom mail body">FarFera@FarFera.com</a> with “Revoke Electronic Record 
                    Consent” in the subject line.
                    To view and retain a copy of this Agreement, you will need (a) a device (such as a 
                    computer or mobile phone) with a web browser and Internet access, and (b) either a 
                    printer or storage space on such device. For a free paper copy, or to update our 
                    records of your contact information, email FarFera at <a href="mailto:FarFera@FarFera.com?body=My custom mail body">FarFera@FarFera.com</a> with 
                    contact information and your mailing address.
                    You agree that all terms and conditions, agreements, notices, disclosures, and other 
                    communications that FarFera provides to you electronically satisfy any legal requirement that 
                    such communications would satisfy if they were in writing. You agree to keep your contact 
                    information, including email address, current. This paragraph does not affect your statutory 
                    rights.
                    </p>
                    <h5 className='text-creamy'>
                    11. Intellectual Property Ownership
                    </h5>
                    <p  className='text-default'>
                    FarFera alone (and its licensors, where applicable) shall own all right, title, and interest, 
                    including all related intellectual property rights, in and to the Technology and the Services. 
                    This Agreement is not a sale and does not convey to you any rights of ownership in or 
                    related to the Technology or the Services, or any intellectual property rights owned by 
                    FarFera. FarFera names, FarFera logos, and the product names associated with the 
                    Technology and Services are trademarks of FarFera or third parties, and no right or license 
                    is granted to use them. You agree that you will not remove, alter, or obscure any copyright, 
                    trademark, service mark, or other proprietary rights notices incorporated in or accompanying 
                    the Technology or the Services.
                    </p>
                    <h5 className='text-creamy'>
                    12. Payment Terms
                    </h5>
                    <p  className='text-default'>
                    (a) <this.BoldText>Sales</this.BoldText>.
                    <br></br>
                    The customer connects to FarFera web site or applications and selects the items that he 
                    wishes to buy. He then fills his identity information, accepts the terms and clicks on the “Pay” 
                    button. The customer is thus redirected to the Centre Monétique Interbancaire online secure 
                    payment platform where he completes his payment information and validates the payment.
                    <br></br>
                    <br></br>
                    (b) <this.BoldText>Payment Methods</this.BoldText>.
                    <br></br>
                    To pay on Internet by credit card, the customer is called to enter his payment card 
                    credentials on the secure payment page. When the transaction is accepted by the bank, the 
                    settlement to debit his account is made in the working-day following the date of the 
                    transaction confirmation.
                    Online payment with credit cards is secured by the Centre Monétique Interbancaire that 
                    provides a fully secure payment service.
                    The customer guarantees FarFera web site or applications that he has enough funds 
                    required when using the payment method chosen by him during the payment validation.
                    When paying by credit card, the conditions of this payment method that are contained in the 
                    agreements established between the customer and his issuer bank, and between FarFera
                    web site or applications and his bank, are applied.
                    <br></br>
                    <br></br>
                    (c) <this.BoldText>Data Privacy</this.BoldText>.
                    <br></br>
                    The information requested from the customer during the online payment are required 
                    to process the online transaction. They are treated confidentially. The customer has 
                    the right to consult or rectify his personal data by sending a request via postal mail to 
                    the following address 6 November St, N 14 Nador, 62000 Morocco, or via electronic mail 
                    via the following email address <a href="mailto:FarFera@FarFera.com?body=My custom mail body">FarFera@FarFera.com</a>
                    <br></br>
                    <br></br>
                    (d) <this.BoldText>Proof of Paymants</this.BoldText>.
                    <br></br>
                    The data stored on the Centre Monétique Interbancaire online platform for the account of the 
                    FarFera web site or applications web site are considered as proof of payment for the online 
                    transaction made by the customer.
                    <br></br>
                    <br></br>
                    (e) <this.BoldText>Prices & Charges</this.BoldText>. 
                    <br></br>
                    You understand that: 
                    <br></br>
                    &nbsp;  I. the prices for menu or other items displayed through the Services may differ from the 
                    prices offered or published by Merchants for the same menu or other items and/or 
                    from prices available at third-party websites and that such prices may not be the 
                    lowest prices at which the menu or other items are sold and may change at any time 
                    without notice.
                    <br></br>
                    &nbsp;  II. FarFera has no obligation to itemize its costs, profits, or margins when publishing 
                    such prices.
                    <br></br>
                    &nbsp;  III. pricing may change at any time, in the discretion of FarFera or the Merchant 
                    (depending on which party sets the given price).
                    <br></br>
                    For certain transactions, the subtotals shown at checkout are estimates that may be higher 
                    or lower depending on the final in-store totals. In those situations, FarFera reserves the right 
                    to temporarily authorize or place a hold on your payment method for an amount that may be 
                    greater than the amount shown at checkout and to charge your payment method the final 
                    price after checkout. You are liable for all transaction taxes (other than taxes based on 
                    FarFera’s income), including sales tax, use tax, goods and services tax, and other 
                    transaction taxes if applicable, on the Services provided under this Agreement. If transaction 
                    taxes, including sales tax, use tax, goods and services tax, and other transaction taxes, are 
                    applicable, FarFera reserves the right to charge you additional amounts on account of such 
                    taxes. goods and services tax will be payable in addition to and at the same time as any 
                    amounts payable under these Terms. In the event that the charge to your payment method 
                    may incorrectly differ from the total amount, including subtotal, fees, and gratuity, displayed 
                    to you at checkout and/or after gratuity is selected, FarFera reserves the right to make an 
                    additional charge to your payment method after the initial charge so that the total amount 
                    charged is consistent with the total amount displayed to you at checkout and/or after gratuity 
                    is selected. All payments will be processed by FarFera and/or its payments processor, using 
                    the preferred payment method designated in your account. If your payment details change, 
                    you or your card provider may provide us with updated payment details. We may use these 
                    new details or details from other payment methods on file in order to help prevent any 
                    interruption to your use of the Services. This includes our right to charge any payment 
                    method on file if your initial form of preferred payment fails. It is your responsibility to keep 
                    your billing information up to date.
                    <br></br>
                    <br></br>
                    (f) <this.BoldText>Refunds</this.BoldText>.
                    <br></br>
                    Morocco Orders. This Section 12(f) applies to Morocco Orders. Charges paid by you for 
                    completed and delivered orders, or for orders confirmed by a Merchant, are final and non-refundable. FarFera has no obligation to provide refunds or credits but may grant them 
                    gratuitously at FarFera’s sole discretion in each case. You may be required to provide 
                    identification information and/or a signature upon pick up and/or receipt of certain orders, as 
                    communicated at the time you place your order. if you fail to provide the required 
                    identification information or signature for such an order, you agree and acknowledge that we 
                    will not honor any claim that your order was not delivered and you may be subject to a non-refundable restocking fee.
                    In order to make a claim for a refund or credit, please follow the procedures set out in the 
                    Technology or Services.
                    <br></br>
                    <br></br>
                    (g) <this.BoldText>Promotional Offers and Credits</this.BoldText>. 
                    <br></br>
                    FarFera, at its sole discretion, may make promotional 
                    offers with different features and different pricing to any User. These promotional offers are 
                    subject to the terms of this Agreement and may be valid only for certain Users as indicated in 
                    the offer. You agree that promotional offers:
                    <br></br> 
                    &nbsp;  I. may only be used by the intended audience, for the intended purpose, and in a lawful 
                    manner.
                    <br></br>
                    &nbsp;  II. may not be duplicated, sold, or transferred in any manner, or made available to the 
                    general public, unless expressly permitted by FarFera.
                    <br></br>
                    &nbsp;  III. they are subject to the specific terms that FarFera establishes for such promotional 
                    offer.
                    <br></br>
                    &nbsp;  IV. cannot be redeemed for cash or cash equivalent.
                    <br></br>
                    &nbsp;  V. they are not valid for use after the date indicated in the offer or in the FarFera Terms 
                    and Conditions for Promotional Offers and Credits, which is, to the extent permitted 
                    by applicable law, incorporated in this Agreement by reference. 
                    <br></br>
                    FarFera reserves the right to withhold or deduct credits or benefits obtained through a 
                    promotion, or to charge additional amounts that would have applied to the transaction had 
                    the promotion not applied, in the event that FarFera determines or believes on reasonable 
                    grounds that the redemption of the promotion or receipt of the credit or benefit was in error, 
                    fraudulent, illegal, or in violation of the applicable promotion terms or this Agreement. 
                    FarFera reserves the right to modify or cancel an offer at any time. The FarFera Terms and 
                    Conditions for Promotional Offers and Credits apply to all promotional offers. You agree that 
                    we may change the FarFera Terms and Conditions for Promotional Offers and Credits at any 
                    time. FarFera may also offer gratuitous credits, which can be used for the Services. Any 
                    credit issued by FarFera under this Section 12(g) is valid for 6 months from the date of issue 
                    except to the extent prohibited under applicable law and may not be redeemed for cash or 
                    cash equivalent. however, your credits may expire earlier if your account is deactivated. 
                    Upon expiration, credits will be removed from your account. Expired credits are no longer 
                    redeemable and cannot be used towards any order. Credits issued to a User’s FarFera
                    account may only be redeemed through that respective brand’s Services.
                    <br></br>
                    <br></br>
                    (h) <this.BoldText>Fees for Services</this.BoldText>. 
                    <br></br>
                    FarFera may change the fees that FarFera charges you as we deem 
                    necessary or appropriate for our business, including but not limited to Delivery Fees, Service 
                    Fees, Small Order Fees, Expanded Range Fees, Regulatory Response Fees, and Surge 
                    Fees. FarFera may offer different pricing to customers based on a variety of factors, 
                    including but not limited to geographic areas or usage. FarFera may also charge you 
                    additional fees as required by law. Further, FarFera may charge Merchants fees on orders 
                    that you place through the Services, including commissions and other fees, and may change 
                    those Merchant fees as we deem necessary or appropriate for our business or to comply 
                    with applicable law. FarFera may charge you a Service Fee for the convenience of 
                    ordering through the FarFera platform. None of the Service Fee, Delivery Fee, Small 
                    Order Fee, Surge Fee, or any other fee charged to you by FarFera is for any right to 
                    access, install, or use any Technology.
                    <br></br>
                    <br></br>
                    (i) <this.BoldText>Referral Program</this.BoldText>. 
                    <br></br>
                    FarFera’s Referral Program Terms and Conditions are incorporated 
                    herein by reference. Under the referral program (“Referral Program”), FarFera offers its 
                    registered Users in good standing the opportunity to earn gratuitous FarFera credits as 
                    promotional rewards by inviting their eligible friends to register as new FarFera Users and 
                    place their initial order through the Services by using a unique referral ID link (“Personal 
                    Link”). For each eligible referral generated through a User’s Personal Link, the User may 
                    receive a gratuitous credit as specified on FarFera’s Referral Program page. You agree that 
                    we may change the terms and conditions of the Referral Program or terminate the Referral 
                    Program at any time. If you do not agree to these changes, you should immediately cease 
                    participating in the Referral Program. Any credit issued by FarFera under this Section 12(i) is 
                    valid for 6 months from the date of issue except to the extent prohibited under applicable law 
                    and may not be redeemed for cash or cash equivalent. however, your credits may expire 
                    earlier if your account is deactivated. Upon expiration, credits will be removed from your 
                    account. Expired credits are no longer redeemable and cannot be used towards any order. 
                    Credits issued to a User’s FarFera account may only be redeemed through that respective 
                    brand’s Services.
                    <br></br>
                    <br></br>
                    (j) <this.BoldText>Gift Cards</this.BoldText>. 
                    <br></br>
                    Except as provided below, gift cards may be redeemable towards eligible 
                    orders placed on <a href="farfera.com">www.FarFera.com</a> or in the FarFera mobile application or. Gift cards are 
                    issued by the FarFera sarl in Morocco. Gift cards are not redeemable for cash except when 
                    required by applicable law. Gift cards are governed by the following Gift Card Terms and 
                    Conditions, which are incorporated herein by reference:
                    For gift cards purchased in Morocco, <a href="">see here</a>.
                    </p>
                    <h5 className='text-creamy'>
                    13. Third-Party Interactions
                    </h5>
                    <p className='text-default'>
                    (a) <this.BoldText>Third-Party Websites, Applications, and Advertisements</this.BoldText>.
                    <br></br>
                    The Services may contain 
                    links to third-party websites, (“Third-Party Websites”), applications (“Third-Party 
                    Applications”), and advertisements (“Third-Party Advertisements”) (collectively, “Third-Party Websites & Advertisements”). When you click or tap on a link to a Third-Party 
                    Website, Third-Party Application, or Third-Party Advertisement, FarFera will not warn you 
                    that you have left FarFera’s website or Services and will not warn you that you are subject to 
                    the terms and conditions (including privacy policies) of another website or destination. Such 
                    Third-Party Websites & Advertisements are not under the control of FarFera. FarFera is not 
                    responsible for any Third-Party Websites & Advertisements. FarFera provides links to these 
                    Third-Party Websites & Advertisements only as a convenience and does not review, 
                    approve, monitor, endorse, warrant, or make any representations with respect to such Third-Party Websites & Advertisements or their products or services. You use all links in Third-Party Websites & Advertisements at your own risk. You should review applicable terms and 
                    policies, including privacy and data gathering practices, of any Third-Party Websites or Third-Party Applications, and make whatever investigation you feel necessary or appropriate 
                    before proceeding with any transaction with any third party.
                    <br></br>
                    <br></br>
                    (b) <this.BoldText>App Stores</this.BoldText>.
                    <br></br> 
                    You acknowledge and agree that the availability of the Technology and the 
                    Services is dependent on the third party from which you received the application license, 
                    such as the Apple or Android app store (each, an “App Store”). You acknowledge and agree 
                    that this Agreement is between you and FarFera and not with the App Store. FarFera, not the 
                    App Store, is solely responsible for the Technology and the Services, including the mobile 
                    application(s), the content thereof, maintenance, support services, and warranty therefor, 
                    and addressing any claims relating thereto (for example, product liability, legal compliance, 
                    or intellectual property infringement). In order to use the Technology and the Services, you 
                    must have access to a wireless network, and you agree to pay all fees associated with such 
                    access. You also agree to pay all fees (if any) charged by the App Store in connection with 
                    the Technology or the Services. You agree to comply with, and your license to use the 
                    Technology and the Services is conditioned upon your compliance with, all applicable third-party terms or agreements (for example, the App Store’s terms and policies) when using the 
                    Technology or the Services. You represent and warrant that you are not located in a country 
                    that is subject to a United States Government embargo or similar laws of other countries 
                    where applicable, or that has been designated by the United States or Canadian 
                    Government as a “terrorist supporting” country, and you represent and warrant that you are 
                    not listed on any United States or Canadian Government list of prohibited or restricted 
                    parties. You acknowledge and agree that each App Store (and its affiliates) is an intended 
                    third-party beneficiary of this Agreement and has the right to enforce the terms and 
                    conditions of this Agreement.
                    </p>
                    <h5 className='text-creamy'>
                    14. Transactions Involving Alcohol and Other Age-Restricted Products
                    </h5>
                    <p className='text-default'>
                    You may have the option to request delivery or pickup of alcohol products in some locations 
                    and from certain Merchants. You agree that you will only order alcohol products if you, the 
                    intended recipient, and anyone who may consume the alcohol products are of legal age to 
                    purchase and consume alcohol products in the relevant jurisdiction. You also agree that, 
                    upon delivery or pickup of alcohol products, the recipient of any alcohol products will provide 
                    valid government-issued identification proving the requisite legal age and that the recipient 
                    will not be intoxicated when receiving such products. If you order alcohol products, you 
                    understand and acknowledge that the order will only be delivered if the Merchant accepts 
                    your order. The person delivering alcohol may be legally obligated to refuse delivery 
                    pursuant to applicable federal, state, provincial, or local laws, rules, or regulations, including 
                    if the recipient is not of legal age, is visibly intoxicated, or is not physically present to accept 
                    the delivery, and will be prompted to refuse delivery if the recipient cannot provide a valid 
                    government-issued identification proving requisite legal age, is visibly intoxicated, or is not 
                    physically present to accept the delivery. If the delivery of alcohol products cannot be 
                    completed for one or more of these reasons, you agree and acknowledge that your purchase 
                    is non-refundable and you or the intended recipient may not receive any part of the 
                    applicable order, including any non-alcohol items that you may have purchased with your 
                    alcohol products, and you may also be subject to a non-refundable restocking fee. Alcohol is 
                    not available in all locations that are served by the FarFera platform. Alcohol may be 
                    removed from the platform for specific Users at FarFera’s sole discretion.
                    You may also have the option to request delivery or pickup of other age-restricted items from 
                    certain Merchants, such as certain over-the-counter medication or other products subject to 
                    applicable federal, state, provincial, or local laws, rules, or regulations or Merchant or 
                    FarFera policies requiring age verification. You agree that you will only order age-restricted
                    items if you, the intended recipient, or anyone who may consume such products are of legal 
                    age to purchase the items. You also agree that, upon delivery or pickup of age-restricted 
                    items, the recipient will provide valid government-issued identification proving the requisite 
                    legal age for such age-restricted items. The person delivering age-restricted items will refuse 
                    delivery pursuant to applicable federal, state, provincial, or local laws, rules, or regulations, 
                    including if the recipient cannot provide a valid government-issued identification proving 
                    requisite legal age or is not physically present to accept the delivery. If the delivery of age-restricted items cannot be completed for one or more of these reasons, you agree and 
                    acknowledge that your purchase is non-refundable and you may not receive any part of your 
                    order, including items that are not age-restricted that you may have purchased with your age-restricted items, and you may also be subject to a non-refundable restocking fee.
                    </p>
                    <h5 className='text-creamy'>
                    15. Indemnification
                    </h5>
                    <p className='text-default'>
                    To the extent permitted by law, you agree to indemnify and hold harmless FarFera and its 
                    officers, directors, employees, agents, and affiliates (each, an “Indemnified Party”) from and 
                    against any losses, claims, actions, costs, damages, penalties, fines, and expenses, 
                    including without limitation, legal and/or attorneys’ fees and expenses, that may be incurred 
                    by an Indemnified Party arising out of, relating to, or resulting from 
                    <br></br>
                    &nbsp;  (a) your User Content.
                    <br></br>
                    &nbsp;  (b) your misuse of the Technology or Services.
                    <br></br>
                    &nbsp;  (c) your breach of this Agreement or any representation, warranty, condition, or covenant in 
                    this Agreement.
                    <br></br>
                    &nbsp;  (d) your violation of any applicable laws, rules, or regulations through or related to the use of 
                    the Technology or Services. 
                    <br></br>
                    In the event of any claim, allegation, suit, or proceeding alleging any matter potentially 
                    covered by the agreements in this Section 15, you agree to pay for the defense of the 
                    Indemnified Party, including reasonable costs and legal and/or attorneys’ fees incurred by 
                    the Indemnified Party. FarFera reserves the right, at its own cost, to assume the exclusive 
                    defense and control of any matter otherwise subject to indemnification by you, in which event 
                    you will fully cooperate with FarFera in asserting any available defenses. This Section 15 
                    does not require you to indemnify any Indemnified Party for any unconscionable commercial 
                    practice by such party, or for such party’s negligence, fraud, deception, false promise, 
                    misrepresentation or concealment, suppression, or omission of any material fact in 
                    connection with the Technology or Services. You agree that the provisions of this Section 15
                    will survive any termination of your account, this Agreement, or your access to the 
                    Technology and/or Services.
                    </p>
                    <h5 className='text-creamy'>
                    16. Disclaimer of Warranties
                    </h5>
                    <p className='text-default'>
                    YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE 
                    TECHNOLOGY AND SERVICES IS ENTIRELY AT YOUR OWN RISK. CHANGES ARE 
                    PERIODICALLY MADE TO THE TECHNOLOGY AND SERVICES AND MAY BE MADE AT 
                    ANY TIME WITHOUT NOTICE TO YOU. FARFERA WILL USE REASONABLE 
                    ENDEAVORS TO ENSURE THE TECHNOLOGY AND SERVICES ARE AVAILABLE AS 
                    MUCH OF THE TIME AS POSSIBLE, BUT DOES NOT GUARANTEE IT WILL BE 
                    AVAILABLE ALL OF THE TIME. THE TECHNOLOGY AND SERVICES ARE PROVIDED ON 
                    AN “AS IS” BASIS WITHOUT GUARANTEES, WARRANTIES, OR CONDITIONS OF ANY 
                    KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, 
                    GUARANTEES, WARRANTIES, OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR 
                    A PARTICULAR OR GENERAL PURPOSE, AND NON-INFRINGEMENT. FARFERA
                    MAKES NO WARRANTIES, CONDITIONS, OR REPRESENTATIONS ABOUT THE 
                    ACCURACY, RELIABILITY, COMPLETENESS, ORTIMELINESS OF THE CONTENT MADE 
                    AVAILABLE THROUGH THE TECHNOLOGY OR SERVICES, OR THE SERVICES, 
                    TECHNOLOGY, TEXT, GRAPHICS, OR LINKS.
                    FARFERA DOES NOT WARRANT THAT THE TECHNOLOGY OR SERVICES WILL 
                    OPERATE ERROR-FREE OR THAT THE TECHNOLOGY OR SERVICES ARE FREE OF 
                    COMPUTER VIRUSES AND OTHER HARMFUL MALWARE. IF YOUR USE OF THE 
                    TECHNOLOGY ORSERVICES RESULTS IN THE NEED FOR SERVICING OR 
                    REPLACING EQUIPMENT OR DATA, FARFERA SHALL NOT BE RESPONSIBLE FOR 
                    THOSE ECONOMIC COSTS.
                    </p>
                    <h5 className='text-creamy'>
                    17. Internet Delays
                    </h5>
                    <p className='text-default'>
                    The Technology and Services may be subject to limitations, delays, and other problems 
                    inherent in the use of the Internet and electronic communications. Except as otherwise 
                    required by applicable law and subject to the Non-Excludable Provisions, FarFera is not 
                    responsible for any delays, delivery failures, damage, loss, injury, or other economic damage 
                    resulting from such problems.
                    </p>
                    <h5 className='text-creamy'>
                    18. Termination
                    </h5>
                    <p className='text-default'>
                    If you violate this Agreement, FarFera may respond based on a number of factors including, 
                    but not limited to, the egregiousness of your actions and whether a pattern of harmful 
                    behavior exists.
                    In addition, at its sole discretion, FarFera may modify or discontinue the Technology or 
                    Services, or may modify, suspend, or terminate your access to the Technology or the 
                    Services, for any reason, with or without notice to you and without liability to you or any third 
                    party. In addition to suspending or terminating your access to the Technology or the 
                    Services, FarFera reserves the right to take appropriate legal action, including without 
                    limitation, pursuing civil, criminal, or injunctive redress. Even after your right to use the 
                    Technology or the Services is terminated, this Agreement will remain enforceable against 
                    you. All provisions which by their nature should survive to give effect to those provisions shall 
                    survive the termination of this Agreement.
                    </p>
                    <h5 className='text-creamy'>
                    19. General
                    </h5>
                    <p className='text-default'>
                    (a) No Joint Venture or Partnership. No joint venture, partnership, employment, or agency 
                    relationship exists between you, FarFera, or any third-party provider as a result of this 
                    Agreement or use of the Technology or Services.
                    <br></br>
                    (b) Severability. Except as otherwise provided in this Agreement, if any provision of this 
                    Agreement is found to be invalid or unenforceable under applicable law, the invalidity or 
                    unenforceability of such provision shall not affect the validity or enforceability of the 
                    remaining provisions of this Agreement, which shall remain in full force and effect.
                    <br></br>
                    (c) Accessing and Downloading the Application from the Apple App Store. The 
                    following applies to any Technology accessed through or downloaded from the Apple App 
                    Store (an “App Store Sourced Application”):
                    <br></br>
                    (d) You acknowledge and agree that 
                    <br></br>
                    &nbsp;  (A) the Agreement is concluded between you and 
                    FarFera only, and not Apple. and 
                    <br></br>
                    &nbsp;  (B) FarFera, not Apple, is solely responsible for the App 
                    Store Sourced Application and content thereof. Your use of the App Store Sourced 
                    Application must comply with the App Store Terms of Service.
                    <br></br>
                    &nbsp;    (ii) You acknowledge that Apple has no obligation whatsoever to furnish any maintenance 
                    and support services with respect to the App Store Sourced Application.
                    <br></br>
                    &nbsp;    (iii) In the event of any failure of the App Store Sourced Application to conform to any 
                    applicable warranty, you may notify Apple, and Apple will refund the purchase price, if any, 
                    for the App Store Sourced Application to you and to the fullest extent permitted by applicable 
                    law, Apple will have no other warranty obligation whatsoever with respect to the App Store 
                    Sourced Application. As between FarFera and Apple, any other claims, losses, liabilities, 
                    damages, costs, or expenses attributable to any failure to conform to any warranty or 
                    condition will be the sole responsibility of FarFera.
                    <br></br>
                    &nbsp;    (iv) You and FarFera acknowledge that, as between FarFera and Apple, Apple is not 
                    responsible for addressing any claims you have or any claims of any third party relating to 
                    the App Store Sourced Application or your possession and use of the App Store Sourced 
                    Application, including, but not limited to: (A) product liability claims. (B) any claim that the 
                    App Store Sourced Application fails to conform to any applicable legal or regulatory 
                    requirement. and (C) claims arising under consumer protection or similar legislation.
                    <br></br>
                    &nbsp;    (v) You and FarFera acknowledge that, in the event of any third-party claim that the App 
                    Store Sourced Application or your possession and use of that App Store Sourced Application 
                    infringes that third party’s intellectual property rights, as between FarFera and Apple, 
                    FarFera, not Apple, will be solely responsible for the investigation, defense, settlement, and 
                    discharge of any such intellectual property infringement claim to the extent required by this 
                    Agreement.
                    <br></br>
                    &nbsp;    (vi) You and FarFera acknowledge and agree that Apple, and Apple’s subsidiaries, are thirdparty beneficiaries of this Agreement as related to your license of the App Store Sourced 
                    Application, and that, upon your acceptance of this Agreement, Apple will have the right (and 
                    will be deemed to have accepted the right) to enforce this Agreement as related to your 
                    license of the App Store Sourced Application against you as a third-party beneficiary thereof.
                    <br></br>
                    &nbsp;    (vii) Without limiting any other terms of this Agreement, you must comply with all applicable 
                    third-party terms of agreement when using the App Store Sourced Application.
                    <br></br>
                    (e) Notice. Where FarFera requires that you provide an email address, you are responsible 
                    for providing FarFera with your most current email address. In the event that the last email 
                    address you provided to FarFera is not valid, or for any reason is not capable of delivering to 
                    you any notices required or permitted by this Agreement, FarFera’s dispatch of the email 
                    containing such notice will nonetheless constitute effective notice. You agree that all 
                    agreements, notices, disclosures, payment or renewal notifications, and other 
                    communications that FarFera provides to you electronically (such as through email or posting 
                    through the Services, including in your FarFera account) satisfy any legal requirement that 
                    such communications be in writing or be delivered in a particular manner. You agree that you 
                    have the ability to store such electronic communications such that they remain accessible to 
                    you in an unchanged form. You may give notice to FarFera by visiting our Customer Support 
                    page and initiating a chat with us. Such notice shall be deemed given on the next business 
                    day after such notice is actually received by FarFera.
                    <br></br>
                    (f) Transfer and Assignment. This Agreement, and any rights and licenses granted 
                    hereunder, may not be transferred or assigned by you, but may be assigned by FarFera
                    without restriction. Any attempted transfer or assignment in violation hereof shall be null and 
                    void. This Agreement binds and inures to the benefit of each party and the party’s 
                    successors and permitted assigns.
                    <br></br>
                    (g) Currency. Unless otherwise indicated, all prices and other amounts displayed through 
                    the Services are in the currency of the jurisdiction where delivery or pickup occurs.
                    <br></br>
                    (h) Use Only Where Legally Allowed. You shall not access or use any portion of the
                    Services if you are not legally allowed to do so where you are located.
                    <br></br>
                    (i) Subcontracting. FarFera may subcontract any of its obligations under this Agreement 
                    without your prior written consent.
                    <br></br>
                    (j) Variation of Our Websites or the Technology. We may from time to time vary, modify, 
                    or discontinue, temporarily or permanently, any or all of our websites or the Technology.
                    <br></br>
                    (k) Entire Agreement. This Agreement is the final, complete, and exclusive agreement of 
                    the parties with respect to the subject matter hereof and supersedes and merges all prior 
                    discussions between the parties with respect to such subject matter. However, nothing in this 
                    Agreement shall supersede, amend, or modify the terms of any separate agreement(s) 
                    between you and FarFera relating to your work as an employee or independent contractor, 
                    including, without limitation, any Independent Contractor Agreement governing your efforts
                    as a Contractor.
                    </p>
                    <h5 className='text-creamy'>
                    20. Contact Information
                    </h5>
                    <p className='text-default'>
                    FarFera welcomes your questions or comments regarding this Agreement. Please find our 
                    contact information below:
                    <br></br>
                    Morocco:
                    <br></br>
                    FarFera, sarl.
                    <br></br>
                    6 November St, N 14
                    <br></br>
                    Nador, 62000
                    <br></br>
                    Telephone Number: +212 682 543 714
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Terms;
